import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { AzureStorageService } from 'src/app/services/azure-storage/azure-storage.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AvatarComponent,
      // useExisting: forwardRef(() => AvatarComponent),
    },
  ],
})
export class AvatarComponent implements OnInit, ControlValueAccessor {
  file: string = '';
  @Input() imageData: any;
  @Input() canEdit: boolean = true;
  @Output() dataEvent = new EventEmitter<any>();

  private endpoint = 'https://pcx-image-rec.cognitiveservices.azure.com/';
  private subscriptionKey = '5f78f566acc3430e9482bf56b951960a';
  uploadedImage?: File;
  uploadedImageUrl?: string;
  imageSrc?: string;
  editable: boolean = this.canEdit;

  constructor(
    public dialog: MatDialog,
    private http: HttpClient
  ) // private azureStorageService: AzureStorageService
  {}

  ngOnInit(): void {
    console.log('AvatarComponent ngOnInit canEdit: ', this.canEdit);
    
    if (this.imageData) {
      this.file = this.imageData;
    }
  }

  writeValue(_file: string): void {
    this.file = _file;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange = (fileUrl: string) => {};

  onTouched = () => {};

  disabled: boolean = false;

  onFileChange(event: any) {
    const files = event.target.files as FileList;

    if (files.length > 0) {
      const _file = URL.createObjectURL(files[0]);
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.dataEvent.emit(e.target.result);
      };
      
      reader.readAsDataURL(files[0]);
      this.resetInput();
      
      // this.file = _file;
    }
  }

  analyzeImage(imageUrl: string) {
    // Define the API endpoint for image analysis
    const apiEndpoint = `${this.endpoint}/vision/v3.0/analyze?visualFeatures=Categories,Description,Tags`;

    // Set up the request headers
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': this.subscriptionKey,
    });

    // Define the request body with the image URL
    const requestBody = {
      url: imageUrl,
    };

    // Make the POST request to Azure Cognitive Services
    this.http.post(apiEndpoint, requestBody, { headers: headers }).subscribe(
      (response: any) => {
        // Handle the API response here
        console.log('Image analysis response:', response);
      },
      (error) => {
        // Handle errors here
        console.error('Error analyzing image:', error);
      }
    );
  }

  // uploadImage(uploadedImage: any) {
  //   this.azureStorageService.uploadImage(uploadedImage).subscribe(
  //     (imageUrl: string) => {
  //       this.uploadedImageUrl = imageUrl;
  //     },
  //     (error: any) => {
  //       console.error('Error uploading image:', error);
  //     }
  //   );
  // }

  openAvatarEditor(image: string): Observable<any> {
    const dialogRef = this.dialog.open(ImageCropperComponent, {
      maxWidth: '80vw',
      maxHeight: '80vh',
      data: image,
    });

    return dialogRef.afterClosed();
  }

  resetInput() {
    const input = document.getElementById(
      'avatar-input-file'
    ) as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }
}
