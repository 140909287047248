import { Component  } from '@angular/core';

@Component({
  selector: 'app-errorfound',
  templateUrl: './errorfound.component.html',
  styleUrls: ['./errorfound.component.scss']
})
export class ErrorfoundComponent  {



}
