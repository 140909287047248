import { Component, OnInit  } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Pet ConneX';
  client_env_name = environment;

  constructor(private toastr: ToastrService) { }

  ngOnInit(): void {
    console.log('Environment:', this.client_env_name);
    
    // this.toastr.success('Hello world!', 'Toastr fun!');
  }
 
}
