import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appCurrencyFormatter]'
})
export class CurrencyFormatterDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: any) {
    let value = this.el.nativeElement.value;
    value = value.replace(/[^\d.]/g, ''); // Remove any characters that are not numbers or dots
    
    if (value) {
      // Format the number as currency
      this.el.nativeElement.value = parseFloat(value).toFixed(2);
    }
  }

}
