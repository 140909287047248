<div class="avatar-container" >
    <div class="image-container" *ngIf="file; else placeholder"
    [ngClass]="{'box-shadow-avatar': !!file}"
    [ngStyle]="{backgroundImage: 'url(' + file + ')'}">
    </div>

    <ng-template #placeholder>
        <div class="image-container avatar-placeholder">
        </div>
    </ng-template>

    <label for="avatar-input-file" >
        <div class="icon-wrapper" >
            <input type="file" id="avatar-input-file" accept="image/*" (change)="onFileChange($event)" />
            <i class="fa fa-edit"></i>
        </div>        
    </label>

</div>